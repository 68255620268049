<template>
  <div class="auth__header">
    <div class="auth__back-content">
      <router-link class="auth__back-group" to="/"
        ><svg-icon class="icon-arrow-back" name="arrow-back" />
        <span class="auth__back-content">{{ $t('main.back_main') }}</span>
      </router-link>
    </div>
    <Button transparent medium event-none>
      {{ getCurrentLoginUserName }}
    </Button>
  </div>
</template>

<script>
export default {
  computed: {
    getCurrentLoginUserName() {
      return this.$route.name === 'auth-key-hotels'
        ? this.$t(`sections.hotel`)
        : this.$t(`main.enter`)
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="sass" scoped>
.auth__header
  display: flex
  justify-content: space-between
  padding: 1rem 6.4rem
  @include s
    padding-right: 2.4rem
    padding-left: 2.4rem
  @include xs
    padding-right: 1.6rem
    padding-left: 1.6rem
  border-bottom: 1px solid $gray

.auth__back
  display: flex
  justify-content: space-between

.auth__back-content
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1.6rem
  color: $black

.auth__back-group
  display: flex
  align-items: center
  text-decoration: none
  gap: 1rem
  &:hover
    cursor: pointer
    opacity: .7
</style>

